<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2021-04-30 23:30 -->
<template>
  <div>
    <TopNav ref="elTopNav"></TopNav>
    <Layout>
      <router-view></router-view>
    </Layout>
    <!-- <div class="container mt-3"> -->
    <!--   <router-view></router-view> -->
    <!-- </div> -->
  </div>
</template>
<script>
import TopNav from "./TopNav.vue";
import Layout from "./Layout.vue";
import $ from "jquery";
export default {
  components: {
    TopNav,
    Layout
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    //
  }),

  computed: {
    //
  },

  // watch: {},

  mounted() {
    var path = window.location.href; // because the 'href' property of the DOM element is the absolute path
    $("#layoutSidenav_nav .sb-sidenav a.nav-link").each(function () {
      if (this.href === path) {
        $(this).addClass("active");
      }
    });

    // Toggle the side navigation
    $("#sidebarToggle").on("click", function (e) {
      e.preventDefault();
      $("body").toggleClass("sb-sidenav-toggled");
    });
  },

  methods: {
    //
  }
};
</script>

<style scoped></style>
